






import Vue from 'vue';
import PracticeTest from '~/components/test/PracticeTest.vue';

export default Vue.extend({
  name: 'PracticePage',
  components: {PracticeTest},
  watch: {
    '$store.state.practice.activeTestLoaded'() {
      if (!this.$store.state.practice.testInstance) {
        this.$router.push({name: 'dashboard'});
      }
    },
  },
});
