


















































































import moment from 'moment';
import Vue from 'vue';
import ImageDialog from '~/components/common/ImageDialog.vue';
import {Answer, Figure, Question, TestInstance} from '~/models';

export default Vue.extend({
  components: {ImageDialog},
  name: 'Test',
  data() {
    return {
      img: {dialog: false, src: null},
      elapsedTime: '',
      showConfirmDialog: false,
    };
  },
  mounted() {
    this.updateElapsedTime();
    setInterval(() => this.updateElapsedTime(), 1000);
    window.addEventListener('keyup', this.handleKey);
  },
  destroyed(): void {
    window.removeEventListener('keyup', this.handleKey);
  },
  computed: {
    questionIndex(): number {
      return this.$store.state.practice.questionIndex + 1;
    },
    testInstance(): TestInstance {
      return this.$store.state.practice.testInstance;
    },
    answerAmount(): number {
      return Object.keys(this.$store.state.practice.answers).length;
    },
    question(): Question {
      return this.$store.getters['practice/question'];
    },
    timeLimit(): string {
      return moment.utc(this.testInstance.timeLimit * 60 * 1000).format('HH:mm:ss');
    },
  },
  methods: {
    async onSubmitClick() {
      if (this.answerAmount < this.testInstance.questions.length) {
        this.showConfirmDialog = true;
      } else {
        await this.submit();
      }
    },
    async confirmSubmit() {
      await this.submit();
      this.showConfirmDialog = false;
    },
    async submit() {
      try {
        await this.$store.dispatch('practice/submit');
      } catch (e) {
        console.log(e);
      }

      await this.$router.push({name: 'practice-results'});
    },
    updateElapsedTime() {
      if (this.$store.state.practice.startTime) {
        const diff = moment().diff(this.$store.state.practice.startTime);

        if (this.testInstance.timeLimit) {
          const diffInMinutes = diff / (1000 * 60);
          if (diffInMinutes >= this.testInstance.timeLimit) {
            this.submit();
          }
        }

        if (diff < 0) {
          this.elapsedTime = moment.utc(0).format('HH:mm:ss');
        } else {
          this.elapsedTime = moment.utc(diff).format('HH:mm:ss');
        }
      }
    },
    showFigure(figure: Figure) {
      this.img.src = figure.path;
      this.img.dialog = true;
    },
    isSelected(answer: Answer) {
      return this.$store.state.practice.answers[this.question.id] === answer.id;
    },
    handleKey(e: KeyboardEvent) {
      const currentAnswerId = this.$store.state.practice.answers[this.question.id];
      const currentAnswerIndex = this.question.answers.findIndex(a => a.id === currentAnswerId);

      switch (e.key) {
        case 'ArrowLeft':
          if (this.questionIndex > 1) {
            this.img.dialog = false;
            return this.$store.dispatch('practice/prevQuestion');
          }
          break;
        case 'ArrowRight':
          if (this.questionIndex < this.testInstance.questions.length) {
            this.img.dialog = false;
            return this.$store.dispatch('practice/nextQuestion');
          }
          break;
        case 'ArrowUp':
          if (currentAnswerIndex > 0) {
            this.$store.dispatch('practice/setAnswer', this.question.answers[currentAnswerIndex - 1]);
          }
          break;
        case 'ArrowDown':
          if (currentAnswerIndex < this.question.answers.length - 1) {
            this.$store.dispatch('practice/setAnswer', this.question.answers[currentAnswerIndex + 1]);
          }
          break;
      }
    },
    getIcon(index: number) {
      switch (index) {
        case 0:
          return 'mdi-alpha-a-circle';
        case 1:
          return 'mdi-alpha-b-circle';
        case 2:
          return 'mdi-alpha-c-circle';
        case 3:
          return 'mdi-alpha-d-circle';
      }
    },
  },
});
